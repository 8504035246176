<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link>
                    <template v-if="account">
                    &gt; <router-link :to="{ name: 'account-dashboard', params: { realm: this.$route.params.accountId } }">{{ account.name }}</router-link>
                    </template>
                </p>
                </v-col>
            </v-row>
            <!-- <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Dynamic DNS records</h1>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5">
                <v-col style="text-align: center" cols="12" sm="6">
                    <v-textarea :value="tinydnsconfig"></v-textarea>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10 mb-8">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-alert type="error" border="left" dense v-if="error">
                        <template #prepend>
                            <font-awesome-icon fixed-width :icon="['fas', 'exclamation-triangle']" class="white--text text-body-1"/>
                        </template>
                        <span class="ml-2">The request could not be processed. Please contact customer support.</span>
                    </v-alert>

                    <v-card tile elevation="4" class="pa-0" v-if="!error">
                        <v-app-bar color="#55308D" dark flat dense>
                            <v-app-bar-title>Create a client token</v-app-bar-title>
                            <!-- <v-spacer/>
                            <v-btn icon @click="createNewItemDialog = true">
                                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
                            </v-btn> -->
                        </v-app-bar>

                        <template v-if="profile === 'dynamic_dns'">
                            <v-card-text>
                                <p>A client token grants permission a Dynamic DNS client program to access your account via the LibertyDNS API and to update Dynamic DNS records on your behalf.</p>

                                <template v-if="!token">
                                    <template v-if="Array.isArray(clientTokenList) && clientTokenList.length > 0">
                                        <p class="mb-0">Current tokens:</p>
                                        <v-list dense v-if="Array.isArray(clientTokenList) && clientTokenList.length > 0">
                                            <v-list-item v-for="(item, idx) in clientTokenList" :key="idx">
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        {{ item.label }}, created {{ formatTimestamp(item.created_on) }}
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>

                                        <p>When you create a new token, the old token will continue to work until you use the new token for the first time.
                                        The first time you use the new token, the old token will be automatically expired.</p>
                                        <v-checkbox v-model="replaceOnFirstUse" label="Automatically expire old token on first use of new token"/>
                                    </template>
                                    <v-btn style="background-color: #55308D; color: #ffffff;" elevation="2" @click="createNewClient" v-if="!client">
                                        Add
                                    </v-btn>
                                    <v-btn style="background-color: #55308D; color: #ffffff;" elevation="2" @click="createClientToken" v-if="client">
                                        Add
                                    </v-btn>
                                </template>
                                <template v-if="token">
                                    <v-alert type="info" border="left" dense v-if="token" class="my-6">
                                        <template #prepend>
                                            <font-awesome-icon fixed-width :icon="['fas', 'info-circle']" class="white--text text-body-1"/>
                                        </template>
                                        <p class="ml-4 mb-0">Copy the token below to configure your Dynamic DNS client program. This is the only time it will be shown.</p>
                                    </v-alert>
                                    <v-tabs v-model="tab">
                                        <v-tabs-slider color="purple darken-2"></v-tabs-slider>
                                        <v-tab>Token</v-tab>
                                        <v-tab-item>
                                            <v-card>
                                            <p class="mb-0 pa-2" style="border: 1px solid black;">{{ token }}</p>
                                            </v-card>
                                        </v-tab-item>
                                        <v-tab>JSON</v-tab>
                                        <v-tab-item>
                                            <v-card>
                                            <p class="mb-0 pa-2" style="border: 1px solid black;">{{ tokenFormatJson }}</p>
                                            </v-card>
                                        </v-tab-item>
                                        <v-tab>INI</v-tab>
                                        <v-tab-item>
                                            <v-card>
                                            <p class="mb-0 pa-2" style="border: 1px solid black;">{{ tokenFormatIni }}</p>
                                            </v-card>
                                        </v-tab-item>
                                        <v-tab>cURL</v-tab>
                                        <v-tab-item>
                                            <v-card>
                                            <p class="mb-0 pa-2" style="border: 1px solid black;">{{ tokenFormatCurl }}</p>
                                            </v-card>
                                        </v-tab-item>
                                    </v-tabs>
                                    <p class="mt-4"><router-link :to="{ name: 'account-search-dynamic-dns-record', params: { accountId: this.$route.params.accountId } }">See all Dynamic DNS records</router-link></p>
                                </template>
                            </v-card-text>
                        </template>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';

export default {
    data: () => ({
        error: null,
        profile: null,
        domain: null,
        host: null,
        replaceOnFirstUse: true,
        account: null,
        client: null,
        clientTokenList: null,
        token: null,
        submitFormTimestamp: null,
        tab: null,
        tabx: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
        }),
        tokenFormatJson() {
            if (this.token) {
                return JSON.stringify({ domain: this.domain, host: this.host, token: this.token }, null, 2);
            }
            return '';
        },
        tokenFormatIni() {
            if (this.token) {
                return `domain=${this.domain}\nhost=${this.host}\ntoken=${this.token}\n`;
            }
            return '';
        },
        tokenFormatCurl() {
            if (this.token) {
                const urltemplate = process.env.VUE_APP_CLIENT_DYNAMIC_DNS_URL_TEMPLATE ?? 'https://client.libertydns.io/edit/dynamic?domain={{domain}}&host={{host}}';
                // TODO: mustache replace domain and host in template, isntead of these replace function calls, so we have more flexiblity with whitespace and escaping
                const urltext = urltemplate.replace('{{domain}}', this.domain).replace('{{host}}', this.host);
                const cmdtemplate = 'curl -X POST -H \'Authorization: Bearer {{token}}\' \'{{url}}\'';
                const cmdtext = cmdtemplate.replace('{{token}}', this.token).replace('{{url}}', urltext);
                return cmdtext;
            }
            return '';
        },
    },
    methods: {
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        formatTimestamp(value) {
            if (Number.isInteger(value) && value >= 0) {
                return new Date(value).toString();
            }
            return '(unknown)';
        },
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        /**
         * the `match` parameter should be either { id } or { label }
         */
        async loadClientByMatch(match) {
            try {
                this.$store.commit('loading', { loadClient: true });
                const response = await this.$client.account(this.$route.params.accountId).client.search(match);
                if (response?.list && response.list.length > 0) {
                    this.client = response.list[0];
                    this.loadClientTokenList({ client_id: this.client.id });
                }
            } catch (err) {
                console.error('loadClient failed', err);
            } finally {
                this.$store.commit('loading', { loadClient: false });
            }
        },
        async loadClientTokenList(match = {}) {
            try {
                this.$store.commit('loading', { loadClientTokenList: true });
                const response = await this.$client.account(this.$route.params.accountId).clientToken.search(match);
                if (response?.list) {
                    this.clientTokenList = response.list;
                }
            } catch (err) {
                console.error('loadClientTokenList failed', err);
            } finally {
                this.$store.commit('loading', { loadClientTokenList: false });
            }
        },
        async createNewClient() {
            if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitFormTimestamp = Date.now();
            try {
                this.$store.commit('loading', { createNewClient: true });
                // is there already a client record to which we need to add
                const item = {
                    profile: this.profile, // currently only 'dynamic_dns' is supported; in the future we will add business & enterprise client profiles to streamline adding other kinds of permissions, and 'custom' to just specify whatever permissions an advanced user wants to grant to the client
                    attr: {
                        domain: this.domain,
                        host: this.host,
                    },
                };
                const response = await this.$client.account(this.$route.params.accountId).client.create(item);
                if (response?.isCreated && response.id) {
                    await this.loadClientByMatch({ id: response.id });
                    await this.createClientToken();
                    // this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
                }
            } catch (err) {
                console.error('createNewClient failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
            } finally {
                this.$store.commit('loading', { createNewClient: false });
            }
        },
        async createClientToken() {
            if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitFormTimestamp = Date.now();
            try {
                this.$store.commit('loading', { createClientToken: true });
                // is there already a client record to which we need to add
                const item = {
                    client_id: this.client.id,
                    replace_on_first_use: this.replaceOnFirstUse,
                };
                const response = await this.$client.account(this.$route.params.accountId).clientToken.create(item);
                if (response?.isCreated && response.id && response.token) {
                    // this.clientTokenList.push(response.item);
                    this.token = response.token;
                    this.loadClientTokenList();
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
                }
            } catch (err) {
                console.error('createClientToken failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
            } finally {
                this.$store.commit('loading', { createClientToken: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
        const { profile, domain, host } = this.$route.query;
        if (profile) {
            switch (profile) {
            case 'dynamic_dns': {
                this.profile = profile;
                if (domain && host) {
                    this.domain = domain;
                    this.host = host;
                    this.loadClientByMatch({ label: `${host}.${domain}` });
                } else {
                    this.error = true;
                }
                break;
            }
            default:
                this.error = true;
            }
        } else {
            this.error = true;
        }
    },
};
</script>
